/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*
## Future considerations for the pattern
-   We don't have a design for form fields that have no space for inline error text.
-   We haven't investigated how Tables should be given errors
-   We don't have a concrete answer for types of server errors (500/400). Most of the app currently uses Toasts for this.
*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner, DataList} from '@servicetitan/design-system';
import {Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    pre: "pre",
    code: "code",
    h2: "h2",
    h3: "h3",
    h4: "h4"
  }, _provideComponents(), props.components);
  if (!DataList) _missingMdxReference("DataList", false);
  if (!DataList.HeaderCell) _missingMdxReference("DataList.HeaderCell", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "getting-started",
    style: {
      position: "relative"
    }
  }, "Getting Started", React.createElement(_components.a, {
    href: "#getting-started",
    "aria-label": "getting started permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When writing an error or warning message, consider these questions:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "What is the issue?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Knowing what the issue is (or knowing that we don't know) can help frame what to communicate to the user."), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "What is the cause of the issue?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Common reasons could be user action, server/network issues, or not being able to find a resource."), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "What can the user do to resolve the issue?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If it's the app fault, explain that. If it's user error, can you identify the most likely cause?"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Is there a path a user can take to resolve the issue?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Whenever it is possible for a user to take action on the issue, a path should be given. For example, if using a Banner or Toast, provide a call to action in them."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Toast\n\ttitle=\"Fixes not applied\"\n\tportal={false}\n\tduration={0}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName={\"Edit bulk fix\"}\n\tstatus=\"critical\"\n>\n\tThe bulk fix you requested was not made because it would cause more errors. Please edit your bulk fix or change the codes manually.\n</Toast>\n")), "\n", React.createElement(_components.h1, {
    id: "quick-use-cases",
    style: {
      position: "relative"
    }
  }, "Quick Use Cases", React.createElement(_components.a, {
    href: "#quick-use-cases",
    "aria-label": "quick use cases permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Each of these is explained in more detail. For needing a component quickly, consider these common scenarios."), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "a",
      content: "Use Case"
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "b",
      content: "Component(s) to use"
    })],
    items: [{
      a: 'Page level error or warning has occurred',
      b: React.createElement(Link, {
        href: "/components/banner"
      }, "Banner")
    }, {
      a: 'Form validation errors',
      b: React.createElement(_components.div, null, React.createElement(Link, {
        href: "/components/form/#errors"
      }, "Inline Error Text"), " inside a Form, a ", React.createElement(Link, {
        href: "/components/banner"
      }, "Banner"), " as a summary")
    }, {
      a: 'Background process problem',
      b: React.createElement(Link, {
        href: "/components/toast"
      }, "Toast")
    }, {
      a: 'No results',
      b: React.createElement(Link, {
        href: "/patterns/empty-states/#error-empty-states"
      }, "Empty State")
    }, {
      a: 'Server/Network error',
      b: React.createElement(_components.div, null, React.createElement(Link, {
        href: "/patterns/empty-states/#error-empty-states"
      }, "Empty State"), " or ", React.createElement(Link, {
        href: "/components/toast"
      }, "Toast"))
    }, {
      a: 'Application wide error or warning',
      b: React.createElement(Link, {
        href: "/components/announcement"
      }, "Announcement")
    }]
  }), "\n", React.createElement(_components.h1, {
    id: "types-of-errors-and-warnings",
    style: {
      position: "relative"
    }
  }, "Types of Errors and Warnings", React.createElement(_components.a, {
    href: "#types-of-errors-and-warnings",
    "aria-label": "types of errors and warnings permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "page-level-messages",
    style: {
      position: "relative"
    }
  }, "Page-level Messages", React.createElement(_components.a, {
    href: "#page-level-messages",
    "aria-label": "page level messages permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "This error type is used when an error applies to the entire page. They are represented as ", React.createElement(_components.a, {
    href: "/components/banner/"
  }, "Banners"), ", placed at the bottom of the ", React.createElement(_components.a, {
    href: "/patterns/page-headers/#notifications"
  }, "Page Header"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 12\n---\n<Banner status=\"critical\" title=\"Fix before posting to batch\">\n\tYou cannot post invoice #2345 because it has an outdated application for payment. Please update all applications in this series and then try again.\n</Banner>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 12\n---\n<Banner\n\tstatus=\"warning\"\n>\n\tUpdate your existing applications for payment before adding more.\n</Banner>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 12\n---\n<Banner\n\tstatus=\"warning\"\n\ttitle=\"Update the invoice\"\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName={\"Save Invoice\"}\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName={\"Not Now\"}\n>\n\tPlease save the invoice to reflect the changes you made to the Application for Payment.\n</Banner>\n")), "\n", React.createElement(_components.p, null, "They can be used to:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Summarize individual errors found on the rest of the page."), "\n", React.createElement(_components.li, null, "Inform the user of important, but non-critical information about the page."), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bolded Headline (optional)"), "\n", React.createElement(_components.li, null, "Sentence explanation & next step"), "\n", React.createElement(_components.li, null, "1-2 CTAs can be buttons or links (optional)"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Headline should be title case if two words or less, otherwise sentence case"), "\n", React.createElement(_components.li, null, "Sentence(s) should be action focused, active voice, and include next steps for user to take. No links in body"), "\n", React.createElement(_components.li, null, "CTAs should be title case for two words or less, sentence case for more"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "form-errors-and-validation",
    style: {
      position: "relative"
    }
  }, "Form Errors and Validation", React.createElement(_components.a, {
    href: "#form-errors-and-validation",
    "aria-label": "form errors and validation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/components/form/#errors"
  }, "Form"), " errors are among the most common type of errors. Each individual component page provides specific styling and text location. It is recommended to also use a ", React.createElement(_components.a, {
    href: "/components/banner/"
  }, "Banner"), " as a summary when multiple errors exist."), "\n", React.createElement(_components.h3, {
    id: "validating-on-submit",
    style: {
      position: "relative"
    }
  }, "Validating on submit", React.createElement(_components.a, {
    href: "#validating-on-submit",
    "aria-label": "validating on submit permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "This validation occurs after a user has hit a save or submit button. These errors are paired with ", React.createElement(_components.a, {
    href: "/components/banner/"
  }, "Banners"), " and individual form field errors."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group widths=\"equal\" className=\"m-b-0\">\n\t\t<Form.Input error label=\"Hours\" value=\"3\" />\n\t\t<BodyText\n\t\t\tsubdued\n\t\t\tclassName=\"m-b-2\"\n\t\t\tstyle={{ alignSelf: \"flex-end\" }}\n\t\t>\n\t\t\tto\n\t\t</BodyText>\n\t\t<Form.Input error label=\" \" value=\"1\" />\n\t</Form.Group>\n\t<Form.Field error=\"Maximum hours must be greater than the minimum\" />\n\t<Form.Group widths=\"equal\" className=\"m-b-0\">\n\t\t<Form.Input label=\"Price\" value=\"2.00\" shortLabel=\"$\" />\n\t\t<BodyText\n\t\t\tsubdued\n\t\t\tstyle={{ alignSelf: \"center\" }}\n\t\t>\n\t\t\tto\n\t\t</BodyText>\n\t\t<Form.Input\n\t\t\terror\n\t\t\tlabel=\" \"\n\t\t\tvalue=\"-4.50\"\n\t\t\tshortLabel=\"$\"\n\t\t\terror=\"Value cannot be negative\"\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h3, {
    id: "validating-before-submission",
    style: {
      position: "relative"
    }
  }, "Validating before submission", React.createElement(_components.a, {
    href: "#validating-before-submission",
    "aria-label": "validating before submission permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When format requirements exist for a field (such as the ", React.createElement(_components.code, null, "@"), " in an email input), validating the form element after a user has left focus can help resolve errors."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "When to use validation before submission:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When an Input has known formatting requirements. If the requirement is ambiguous to a user (e.g. password requirements), add a ", React.createElement(_components.a, {
    href: "/components/form/#field-descriptions"
  }, "field description"), "."), "\n", React.createElement(_components.li, null, "In the initial user interaction, validate only after a user has left focus on the field and if the user entered anything."), "\n", React.createElement(_components.li, null, "When a user interacts with an errored field, validate after each keystroke. This helps the user identify when the user has met the field requirements."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "When to avoid validation before submission:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "While the user is still interacting with the field."), "\n", React.createElement(_components.li, null, "When a field was left empty by the user."), "\n", React.createElement(_components.li, null, "When it takes at least a second for the codebase to validate the field."), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Short fragment or sentence"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sentence case no punctuation"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, {
    id: "banner-summary-of-errors",
    style: {
      position: "relative"
    }
  }, "Banner Summary of Error(s)", React.createElement(_components.a, {
    href: "#banner-summary-of-errors",
    "aria-label": "banner summary of errors permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "It is recommended to add a ", React.createElement(_components.a, {
    href: "/components/banner/#critical"
  }, "Banner"), " at the top of a form that has errors. If the form is the main content of the page, it follows the Page-level guidelines. Otherwise, it appears wherever the top of the form is."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The Banner should only appear after a validating on submit. Validations before submission do not need a Banner."), "\n", React.createElement(_components.li, null, "The summary should list out each of the errors encountered on the page."), "\n", React.createElement(_components.li, null, "In general, the summary should not be used alone. Inline help should be added when possible."), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bolded Headline (optional)"), "\n", React.createElement(_components.li, null, "Sentence explanation & next step"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Headline should be title case if two words or less, otherwise sentence case, no punctuation"), "\n", React.createElement(_components.li, null, "Sentence(s) should be action focused, active voice, and include next steps for user to take"), "\n", React.createElement(_components.li, null, "No links or buttons"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<Card.Section>\n\t<Banner status=\"critical\" title=\"Please fix the following elements and submit again\" className=\"m-b-4\">\n\t\t<ul className=\"p-t-1-i\">\n\t\t\t<li>Make a job selection</li>\n\t\t\t<li>Enter an arrival time after 7:00 AM</li>\n\t\t\t<li>Enter a done date that occurs after the arrival time </li>\n\t\t</ul>\n\t</Banner>\n\t<Form>\n\t\t<Form.AnvilSelect trigger={{placeholder: 'Select a Job'}} label=\"Job #\" error=\"Make a job selection\" options={[]} />\n\t\t<Form.Group widths=\"equal\">\n\t\t\t<Form.Input label=\"Arrived Time\" value=\"01/01/2020\" />\n\t\t\t<Form.Input error=\"Enter an arrival time after 7:00 AM\" label=\" \" value=\"5:15 AM\" />\n\t\t</Form.Group>\n\t\t<Form.Group widths=\"equal\" className=\"m-b-0\">\n\t\t\t<Form.Input label=\"Done Time\" value=\"12/31/2019\" error=\"Enter a done date that occurs after the arrival time\" />\n\t\t\t<Form.Input label=\" \" value=\"11:00 AM\" />\n\t\t</Form.Group>\n\t</Form>\n\t</Card.Section>\n\t<Card.Section>\n\t\t<ButtonGroup className=\"justify-content-end\">\n\t\t\t<Button>Cancel</Button>\n\t\t\t<Button primary >Submit Timesheet</Button>\n\t\t</ButtonGroup>\n\n\t</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "system-and-network-errors",
    style: {
      position: "relative"
    }
  }, "System and Network Errors", React.createElement(_components.a, {
    href: "#system-and-network-errors",
    "aria-label": "system and network errors permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "System errors can typically be described as 400 or 500 type errors. If there is a place for it on the page, it is preferred to integrate an ", React.createElement(_components.a, {
    href: "/patterns/empty-states/#error-empty-states"
  }, "Empty State"), " into the section. Otherwise, using a ", React.createElement(_components.a, {
    href: "/components/toast/#criticalerror"
  }, "Toast"), " is preferred over components such as the Banner or Announcement."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When a system error is the apps fault, take responsibility."), "\n", React.createElement(_components.li, null, "If there is an HTTP response, place it at the end of the body content."), "\n", React.createElement(_components.li, null, "When using the Toast to present an error, it should persist on the page."), "\n", React.createElement(_components.li, null, "When it is possible to determine the solution, provide it to the user. For example, a 401 error resulting from not being logged in."), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bolded Headline"), "\n", React.createElement(_components.li, null, "Sentence explanation (optional)"), "\n", React.createElement(_components.li, null, "Button CTA"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Headline should be title case if two words or less, otherwise sentence case"), "\n", React.createElement(_components.li, null, "Sentence(s) should be action focused, active voice, and include next steps for user to take. No links in body."), "\n", React.createElement(_components.li, null, "CTAs should be title case for two words or less, sentence case for more"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "application-errors-and-warnings",
    style: {
      position: "relative"
    }
  }, "Application Errors and Warnings", React.createElement(_components.a, {
    href: "#application-errors-and-warnings",
    "aria-label": "application errors and warnings permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "App-level messages are used to convey a status relevant to the entire experience, represented with an ", React.createElement(_components.a, {
    href: "/components/announcement/"
  }, "Announcement"), ". These are not used in specific products within the platform. Some examples might include a sandbox environment message and a scheduled downtime notice."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sentences"), "\n", React.createElement(_components.li, null, "Button CTA"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Full sentences with punctuation should be used"), "\n", React.createElement(_components.li, null, "CTA should be title case for two words or less, sentence case for more"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Announcement\n\tstatus=\"warning\"\n\ttitle=\"Your account needs a default credit card. Please add before continuing.\"\n\tonClose={() => {}}\n\tactionName=\"Add default card\"\n\tonActionClick={() => {}}\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "empty-state-errors-and-warnings",
    style: {
      position: "relative"
    }
  }, "Empty State Errors and Warnings", React.createElement(_components.a, {
    href: "#empty-state-errors-and-warnings",
    "aria-label": "empty state errors and warnings permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Many different contexts for an empty state can exist, some of which can be tied to errors and warnings. See the ", React.createElement(_components.a, {
    href: "/patterns/empty-states"
  }, "Empty States"), " pattern page for all variations. Sections relevant to errors include: ", React.createElement(_components.a, {
    href: "/patterns/empty-states/#negative-functional-feedback"
  }, "workflows inhibited by the empty state"), ", ", React.createElement(_components.a, {
    href: "/patterns/empty-states/#error-empty-states"
  }, "parsing, and server errors")), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bolded Headline (optional)"), "\n", React.createElement(_components.li, null, "Sentence(s) explanation"), "\n", React.createElement(_components.li, null, "Button CTA (optional)"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Headline should be title case if two words or less, otherwise sentence case"), "\n", React.createElement(_components.li, null, "Full sentences with punctuation should be used for body copy"), "\n", React.createElement(_components.li, null, "CTA should be title case for two words or less, sentence case for more"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"ta-center m-x-auto\" style={{width:420}}>\n\t<Icon name=\"not_interested\" size={32} className=\"m-b-2 c-red-500\" />\n\t<Headline>No Results Found</Headline>\n\t<BodyText subdued size=\"small\">Try another search or create a new report by clicking the button above.</BodyText>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "errors-vs-warnings",
    style: {
      position: "relative"
    }
  }, "Errors vs Warnings", React.createElement(_components.a, {
    href: "#errors-vs-warnings",
    "aria-label": "errors vs warnings permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Anvil provides the option of warning and error statuses across many of its components. Warnings, usually represented in yellow or orange, are a way of providing a less severe notification to users. They are useful when users should be informed of something, but aren’t necessarily required to take action on something."), "\n", React.createElement(_components.h4, {
    id: "examples-where-warnings-are-preferred",
    style: {
      position: "relative"
    }
  }, "Examples where warnings are preferred", React.createElement(_components.a, {
    href: "#examples-where-warnings-are-preferred",
    "aria-label": "examples where warnings are preferred permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If a user can correct the issue at a later point"), "\n", React.createElement(_components.li, null, "The system detects something might be wrong from a user input"), "\n", React.createElement(_components.li, null, "To signal that significant change may/will happen in the future"), "\n", React.createElement(_components.li, null, "To prevent future errors from occuring."), "\n"), "\n", React.createElement(_components.h4, {
    id: "examples-where-errors-are-preferred",
    style: {
      position: "relative"
    }
  }, "Examples where errors are preferred", React.createElement(_components.a, {
    href: "#examples-where-errors-are-preferred",
    "aria-label": "examples where errors are preferred permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If a user cannot proceed in a task without resolving the issue"), "\n", React.createElement(_components.li, null, "When server or network issues arise"), "\n", React.createElement(_components.li, null, "For form validation"), "\n"), "\n", React.createElement(_components.h1, {
    id: "preventive-measures",
    style: {
      position: "relative"
    }
  }, "Preventive Measures", React.createElement(_components.a, {
    href: "#preventive-measures",
    "aria-label": "preventive measures permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "While we can’t prevent all errors, we can design interfaces that help minimize user mistakes."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "For simple layouts, the primary action can be disabled until all required fields are filled out, and paired with a ", React.createElement(_components.a, {
    href: "/components/tooltip/"
  }, "Tooltip"), " to give the a user a hint. In complex layouts however, a submit-type button can help a user figure out what to do to proceed."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Takeover.Footer>\n\t<ButtonGroup className=\"row-reverse\">\n\t\t<Button>Previous</Button>\n\t\t<Tooltip\n\t\t\ttext=\"Fill out all the fields in the form to proceed.\"\n\t\t\tel=\"div\"\n\t\t\tdirection=\"tl\"\n\t\t\tportal\n\t\t>\n\t\t\t<Button primary disabled>\n\t\t\t\tNext\n\t\t\t</Button>\n\t\t</Tooltip>\n\t</ButtonGroup>\n</Takeover.Footer>\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Adding a ", React.createElement(_components.a, {
    href: "/components/dialog/"
  }, "Dialog"), " if a user is about to leave\nthe page before saving. While this may not produce a tangible UI error, this\nhelps a user avoid mistakes."), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Elements")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bolded Headline"), "\n", React.createElement(_components.li, null, "Sentence explanation"), "\n", React.createElement(_components.li, null, "Button CTA"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Copy Rules")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Headline should be title case for two words or less, sentence case for more"), "\n", React.createElement(_components.li, null, "Sentence(s) should be action focused, active voice, and include next steps for user to take. No links in body."), "\n", React.createElement(_components.li, null, "CTAs should be title case for two words or less, sentence case for more"), "\n"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 230\n---\n<Dialog\n\topen\n\ttitle=\"Save for later?\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Save\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n>\n\tYou can always set up this campaign at a later time.\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 230\n---\n<Dialog\n\topen\n\ttitle=\"Delete Email?\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Delete\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n\tnegative\n>\n\tAre you sure you want to delete this email and it scheduling\n\tinterval?\n</Dialog>\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When form field elements are required, the ", React.createElement(_components.code, null, "*"), " can help users identify what they need to fill out. Alternatively, when most fields are required, you can mark particular ones as optional."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<Form>\n\t<Form.Input\n\t\tlabel=\"Full Name\"\n\t\tlabelProps={{\n\t\t\trequired: true,\n\t\t}}\n\t/>\n\t<Form.Input label=\"Street Address\" />\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<Form>\n\t<Form.Input label=\"Sender Name\" />\n\t<Form.TextArea\n\t\tlabel=\"Legal Copy\"\n\t\tlabelProps={{\n\t\t\toptional: true,\n\t\t}}\n\t\tplaceholder=\"Placeholder Text\"\n\t/>\n</Form>\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When possible, constrain controls to only accept valid values. For example, if a field only accepts numbers, prevent a user from entering letters."), "\n"), "\n", React.createElement(_components.h1, {
    id: "anti-patterns",
    style: {
      position: "relative"
    }
  }, "Anti-Patterns", React.createElement(_components.a, {
    href: "#anti-patterns",
    "aria-label": "anti patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Some components could theoretically be used to display errors, but should be avoided."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Don’t use Modals, Dialogs, or browser alerts to display errors or warnings"), "\nUnless the content already existed in these overlays, these components should not be used to display errors. These components interrupt, adding an extra step to resolve errors."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 320\n---\n<Dialog\n\topen\n\ttitle=\"There were errors with the submission\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Ok\"\n>\n\t<ul className=\"m-b-0 p-l-3\">\n\t\t<li>Password must be at least 8 characters.</li>\n\t\t<li>Submitted password was used in the last 6 months.</li>\n\t</ul>\n</Dialog>\n")), "\n", React.createElement(Banner, {
    status: "critical",
    title: "Poor Error"
  }, React.createElement(Link, {
    href: "/components/banner"
  }, "Banner"), " should be used over a ", React.createElement(Link, {
    href: "/components/dialog"
  }, "Dialog"), " for summaries."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Avoid overlay components to display error messages when an inline variation exists."), " Tooltips and Popovers can prevent an end user from scanning the content of a page."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Don’t validate form elements while a user is still interacting with it."), " Many form elements do not start off validated correctly (e.g. typing out an email at the start will be missing the @)."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Avoid Red Buttons to indicate an error"), ". Red buttons signify that an act is destructive (such as deleting a document or an action that cannot be undone). Other components should be used to signify the presence of an error."), "\n"), "\n", React.createElement(_components.h2, {
    id: "copy-guidelines",
    style: {
      position: "relative"
    }
  }, "Copy Guidelines", React.createElement(_components.a, {
    href: "#copy-guidelines",
    "aria-label": "copy guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content should be clear and easy to understand."), " It should be encouraging without being overly apologetic or humorous."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Avoid development jargon"), ". Users are unlikely to be familiar with the inner workings of the app or web development. Frame copy toward user actions and goals, not the underlying software cause of the problem."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If it's known how to resolve the issue, the copy should explain it."), " For example, \"Enter a maximum price higher than the minimum price.\" is more helpful than \"Enter a valid maximum price\"."), "\n", React.createElement(_components.li, null, "When user action caused the error, avoid outright blame on them."), "\n", React.createElement(_components.li, null, "When the error is caused by the app, accept responsibility and apologize."), "\n"), "\n", React.createElement(_components.h2, {
    id: "external-resources",
    style: {
      position: "relative"
    }
  }, "External Resources", React.createElement(_components.a, {
    href: "#external-resources",
    "aria-label": "external resources permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The Technical Writing team has additional documentation on in-app copy. Related guidelines include ", React.createElement(_components.a, {
    href: "https://servicetitan.atlassian.net/wiki/spaces/DTW/pages/263848031/Error+and+Other+Message+Types+-+Docs"
  }, "Error and Other Message Types"), " and ", React.createElement(_components.a, {
    href: "https://servicetitan.atlassian.net/wiki/spaces/DTW/pages/271581224/Web+UI+Elements+-+Docs"
  }, "Web/UI Elements"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nngroup.com/articles/errors-forms-design-guidelines/"
  }, "NN Group, How to Report Errors in Forms: 10 Design Guidelines")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
